import React from 'react';
import PropTypes from 'prop-types';
import ImageUploader from 'react-images-upload';
import ClipLoader from 'react-spinners/ClipLoader';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';

// @material-ui/icons
import DirectionsCar from '@material-ui/icons/DirectionsCar';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import InfoArea from '../../components/InfoArea/InfoArea';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import { sendImage } from '../../requests/requests';
import Landing from './Landing';
import Checked from '../../assets/img/checked.png';

const loadingStyle = {
  marginTop: '10%'
};

class DriverDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: {
        profile: '',
        selfie: '',
        cnh: '',
        crlv: '',
        records: ''
      },
      responses: {
        profile: false,
        selfie: false,
        cnh: false,
        crlv: false,
        records: false
      },
      loading: false
    };
  }

  handleChange = (id, file) => {
    this.setState(prevState => ({
      images: {
        ...prevState.images,
        [id]: file
      }
    }));
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { images } = this.state;

    sendImage('profile', images.profile[0])
      .then(res => {
        console.log(res.data);

        this.setState(prevState => ({
          responses: {
            ...prevState.responses,
            profile: true
          }
        }));
      })
      .then(() => {
        sendImage('selfie', images.selfie[0])
          .then(res => {
            console.log(res.data);

            this.setState(prevState => ({
              responses: {
                ...prevState.responses,
                selfie: true
              }
            }));
          })
          .catch(err => {
            console.log(err.response);
          });
      })
      .then(() => {
        sendImage('cnh', images.cnh[0])
          .then(res => {
            console.log(res.data);

            this.setState(prevState => ({
              responses: {
                ...prevState.responses,
                cnh: true
              }
            }));
          })
          .catch(err => {
            console.log(err.response);
          });
      })
      .then(() => {
        sendImage('crlv', images.crlv[0])
          .then(res => {
            console.log(res.data);

            this.setState(prevState => ({
              responses: {
                ...prevState.responses,
                crlv: true
              }
            }));
          })
          .catch(err => {
            console.log(err.response);
          });
      })
      .then(() => {
        sendImage('records', images.records[0])
          .then(res => {
            console.log(res.data);

            this.setState(prevState => ({
              responses: {
                ...prevState.responses,
                records: true
              }
            }));
          })
          .catch(err => {
            console.log(err.response);
          });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { classes } = this.props;
    const { responses, loading } = this.state;
    // console.log(responses);

    if (
      responses.profile &&
      responses.cnh &&
      responses.crlv &&
      responses.records &&
      responses.selfie
    ) {
      return <Landing txt="Cadastro em análise" />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Envio de Documentos</h2>
              <CardBody>
                <form
                  onSubmit={evt => {
                    this.setState({ loading: true });
                    this.handleSubmit(evt);
                  }}
                  onInvalid={this.handleError}
                >
                  <GridContainer justify="center">
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        <InfoArea
                          title="Foto de Perfil"
                          icon={DirectionsCar}
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum sed arcu non odio euismod lacinia at quis."
                          iconColor="rose"
                        />
                        <ImageUploader
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 3mb"
                          onChange={file => {
                            this.handleChange('profile', file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={3242880}
                        />
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.profile}
                          />
                        ) : (
                          ''
                        )}
                        {responses.profile ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        <InfoArea
                          title="Selfie"
                          icon={DirectionsCar}
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum sed arcu non odio euismod lacinia at quis."
                          iconColor="rose"
                        />
                        <ImageUploader
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 2mb"
                          onChange={file => {
                            this.handleChange('selfie', file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={2242880}
                        />
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.selfie}
                          />
                        ) : (
                          ''
                        )}
                        {responses.selfie ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={5}>
                        <InfoArea
                          title="CNH"
                          icon={DirectionsCar}
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum sed arcu non odio euismod lacinia at quis."
                          iconColor="rose"
                        />
                        <ImageUploader
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 2mb"
                          onChange={file => {
                            this.handleChange('cnh', file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={2242880}
                        />
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.cnh}
                          />
                        ) : (
                          ''
                        )}
                        {responses.cnh ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={5}>
                        <InfoArea
                          title="Documento do Carro"
                          icon={DirectionsCar}
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum sed arcu non odio euismod lacinia at quis."
                          iconColor="rose"
                        />
                        <ImageUploader
                          id="crlv"
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 2mb"
                          onChange={file => {
                            this.handleChange('crlv', file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={2242880}
                        />
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.crlv}
                          />
                        ) : (
                          ''
                        )}
                        {responses.crlv ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider variant="inset" style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={5}>
                        <InfoArea
                          title="Ficha Criminal"
                          icon={DirectionsCar}
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum sed arcu non odio euismod lacinia at quis."
                          iconColor="rose"
                        />
                        <ImageUploader
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 2mb"
                          onChange={file => {
                            this.handleChange('records', file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={2242880}
                        />
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.records}
                          />
                        ) : (
                          ''
                        )}
                        {responses.records ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Button round color="info" type="submit">
                      Enviar
                    </Button>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DriverDocs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(DriverDocs);
