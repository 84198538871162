/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, CardContent, ButtonBase, Button, IconButton } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SweetAlert from 'react-bootstrap-sweetalert';

import { updateDriver, updateDriverDocument, createSplitter } from '../../requests/requests';
import Edit from '@material-ui/icons/Edit';

export default function Driver() {
  const [driver, setDriver] = useState(JSON.parse(sessionStorage.getItem('driverDetail')));
  const [driverDocuments, setDriverDocuments] = useState(JSON.parse(sessionStorage.getItem('driverDetail')).documents); 
  const [alert, setAlert] = useState();

  

  function hideAlert() {
    setAlert(null);
  }

  function showAlert(document, approved) {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title={approved ? 'Aprovar documento' : 'Reprovar documento'}
        onConfirm={() => approveDocument(document, approved)}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não!"
      >
        Você tem certeza que quer {approved ? 'aprovar o documento' : 'reprovar o documento'}?
      </SweetAlert>
    );
  }

  async function approveDocument(document, approved) {
    
    hideAlert();
    const res = await updateDriverDocument(driver.user.id, document, approved);
    if (res.data) {
      setDriverDocuments(res.data);
    }
  }

  if (!driver) {
    // window.location.href = '/driver-history';
    return <div />;
  }
  return (
    <div>
      {alert}
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <h3>{driver.user.name}</h3>
              <h5>{driver.user.email}</h5>
              <p>
                <b>
                  {driver.approved_at ? `Aprovado em: ${driver.approved_at}` : ''}
                  {driver.reproved_at ? `Reprovado em: ${driver.reproved_at}` : ''}
                  <br />
                </b>
              </p>
              <p>
                {driver.user.phone}
                <br />
                {driver.user.cpf}
                <br />
                {driver.user.signup_date}
                <br />
              </p>
              <h5>Endereço</h5>
              <p>
                {`${
                  driver.user.address.city.nome
                }, ${driver.user.address.city.state.nome.toUpperCase()}`}
                <br />
                {`${driver.user.address.line1}, ${driver.user.address.number}`}
                <br />
                {driver.user.address.line2}
                <br />
                {`CEP: ${driver.user.address.zipcode}, ${driver.user.address.district}`}
                <br />
              </p>
              <h5>Veículo</h5>
              <p>
                {`${driver.vehicle.plate.substring(0, 3)}-${driver.vehicle.plate.substring(3)}`}
                <br />
                {driver.vehicle.type.name}
                <br />
                {driver.vehicle.description}
                <br />
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item md={6} xs={12} direction="column">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Documentos do parceiro
              </ListSubheader>
            }
            style={{
              width: '100%',
              maxWidth: 360,
              backgroundColor: '#FFFFFF'
            }}
          >
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.selfie ? (
                    <a href={driverDocuments.selfie} target="_blank">
                      <ListItemText primary="Selfie" />
                    </a>
                  ) : (
                    <ListItemText primary="Selfie" secondary="Faltando" />
                  )}
                </Grid>
                {driverDocuments.selfie_locked ? (                  
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : (
                  driverDocuments.selfie ? 
                    <React.Fragment>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('selfie', true)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('selfie', false)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                    : ''
                )}
              </Grid>
            </ListItem>
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.cnh ? (
                    <a href={driverDocuments.cnh} target="_blank">
                      <ListItemText primary="CNH" />
                    </a>
                  ) : (
                    <ListItemText primary="CNH" secondary="Faltando" />
                  )}
                </Grid>
                {driverDocuments.cnh_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : (
                  driverDocuments.cnh ? (
                    <React.Fragment>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('cnh', true)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('cnh', false)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ) : ''
                )}
              </Grid>
            </ListItem>
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.vehicle_document ? (
                    <a href={driverDocuments.vehicle_document} target="_blank">
                      <ListItemText primary="CRLV" />
                    </a>
                  ) : (
                    <ListItemText primary="CRLV" secondary="Faltando" />
                  )}
                </Grid>
                {driverDocuments.vehicle_document_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : (
                  driverDocuments.vehicle_document ? (
                    <React.Fragment>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('vehicle_document', true)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('vehicle_document', false)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ) : ''
                )}
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.criminal_records ? (
                    <a href={driverDocuments.criminal_records} target="_blank">
                      <ListItemText primary="Certidão de Bons Antecedentes" />
                    </a>
                  ) : (
                    <ListItemText primary="Certidão de Bons Antecedentes" secondary="Faltando" />
                  )}
                </Grid>
                {driverDocuments.criminal_records_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : (
                  driverDocuments.criminal_records ? (
                    <React.Fragment>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('criminal_records', true)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={2}>
                        <IconButton onClick={() => showAlert('criminal_records', false)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ) : ''
                )}
              </Grid>
            </ListItem>
          </List>
        </Grid>
        {driverDocuments.selfie && driverDocuments.cnh && driverDocuments.vehicle_document && driverDocuments.criminal_records ? (
          <Grid container item md={12} alignItems="center" justify="center">
            <Button
              variant="contained"
              onClick={evt => {
                evt.preventDefault();
                updateDriver(driver.user.id, false).then(res => {
                  if (res.data) {
                    setDriver(res.data);
                    sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
                  }
                });
              }}
              style={{
                color: 'red',
                padding: '8px',
                display:
                  driver.approved_at || (!driver.approved_at && !driver.reproved_at)
                    ? 'block'
                    : 'none'
              }}
            >
              Reprovar
            </Button>
            <div style={{ width: '10px' }} />
            <Button
              variant="contained"
              onClick={evt => {
                evt.preventDefault();
                updateDriver(driver.user.id, true).then(res => {
                  if (res.data) {
                    setDriver(res.data);
                    sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
                  }
                });
              }}
              style={{
                color: 'green',
                padding: '8px',
                display:
                  driver.reproved_at || (!driver.approved_at && !driver.reproved_at)
                    ? 'block'
                    : 'none'
              }}
            >
              Aprovar
            </Button>
          </Grid>
        ) 
        : ''}
      </Grid>
    </div>
  );
}
