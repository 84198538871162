import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Grid, Card, CardContent, IconButton } from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';

import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import { getSplitters } from '../../requests/requests';
import Splitter from '../Pages/Splitter';

export default function Splitters() {
  const [goToSplitter, setGoToSplitter] = useState({ status: false, id: null });
  const [loading, setLoading] = useState(true);
  const [splitters, setSplitters] = useState([]);
  const [pages, setPages] = useState(0);
  const [filterState, setFilterState] = useState(null);

  async function fetchData(state) {
    setLoading(true);
    const splitterName = state.filtered.find(obj => obj.id === 'colName');

    try {
      const res = await getSplitters(
        state.page * state.pageSize,
        state.pageSize,
        splitterName ? splitterName.value : ''
      );
      res.data.splitters.forEach(splitter => {
        splitter.btn = (
          <div className="actions-right" style={{ float: 'right' }}>
            <IconButton
              color="default"
              onClick={evt => {
                evt.preventDefault();
                setGoToSplitter({ status: true, id: splitter.id });
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      });
      setSplitters(res.data.splitters);
      setPages(Math.ceil(res.data.count / state.pageSize));
      setLoading(false);
    } catch (err) {
      setLoading();
      console.log('err', err.response);
    }
  }

  useEffect(() => {
    if (filterState !== null) {
      let timeOutFunction = setTimeout(function() {
        fetchData(filterState);
      }, 500);

      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [filterState]);

  if (goToSplitter.status) {
    return <Splitter splitterId={goToSplitter.id} />;
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4>Recebedores</h4>
          </CardHeader>
          <CardContent>
            <ReactTable
              className="-striped -highlight"
              data={splitters}
              pages={pages}
              sortable={false}
              loading={loading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => setFilterState(state)}
              onFilteredChange={state => setFilterState(state)}
              filter
              columns={[
                {
                  id: 'colName',
                  Header: 'Nome',
                  accessor: 'name',
                  maxWidth: 200,
                  filterable: true
                },
                {
                  Header: 'Dias para repasse',
                  accessor: 'transferDays',
                  style: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' },
                  maxWidth: 200
                },
                {
                  id: 'colBtn',
                  Header: '',
                  accessor: row => row.btn,
                  sortable: false,
                  filterable: false
                }
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
