import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { withMobileDialog, Grid, Card, CardContent, Button, IconButton } from '@material-ui/core';
import ReactTable from 'react-table';

// icons
import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';

import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import { formatTimeDate } from '../../helpers/functions';
import { getRides } from '../../requests/requests';

function RideHistory(props, { fullscreen }) {
  const [loading, setLoading] = useState(true);
  const [rides, setRides] = useState([]);
  const [pages, setPages] = useState(0);
  const [filterState, setFilterState] = useState(null);
  const [goToRide, setGoToRide] = useState({ status: false, ride: {} });

  async function fetchData(state) {
    setLoading(true);

    // FIXME

    const status = state.filtered.find(obj => obj.id === 'colStatus');

    const destination = state.filtered.find(obj => obj.id === 'destinationAddress');

    const driverName = state.filtered.find(obj => obj.id === 'colDriver');

    try {
      const res = await getRides(
        state.page * state.pageSize,
        state.pageSize,
        driverName ? driverName.value : '',
        destination ? destination.value : '',
        status ? status.value : ''
      );
      console.log(state.filtered);
      res.data.rides.forEach(ride => {
        ride.btn = (
          <div className="actions-right" style={{ float: 'right' }}>
            <IconButton
              color="default"
              onClick={evt => {
                evt.preventDefault();
                setGoToRide({ status: true, ride });
              }}
            >
              <Search />
            </IconButton>
          </div>
        );
      });
      setRides(res.data.rides);
      setPages(Math.ceil(res.data.count / state.pageSize));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.response);
    }
  }

  useEffect(() => {
    if (filterState !== null) {
      let timeOutFunction = setTimeout(function() {
        fetchData(filterState);
      }, 500);

      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [filterState]);

  if (goToRide.status) {
    sessionStorage.setItem('ride', JSON.stringify(goToRide.ride));
    props.history.push('/ride-history');
    return <Redirect to="/ride" />;
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4>Corridas</h4>
          </CardHeader>
          <CardContent>
            <ReactTable
              className="-striped -highlight"
              data={rides}
              pages={pages}
              sortable={false}
              loading={loading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => setFilterState(state)}
              onFilteredChange={state => setFilterState(state)}
              filter
              columns={[
                {
                  Header: 'Destino',
                  accessor: 'destinationAddress',
                  width: 200,
                  filterable: true
                },
                {
                  id: 'colDistance',
                  Header: 'Distancia',
                  accessor: row => `${(row.distance / 1000).toFixed(1).replace('.', ',')} km`,
                  width: 100
                },
                {
                  id: 'colStatus',
                  Header: 'Status',
                  accessor: row => (row.lastStatus ? row.lastStatus.name : '~'),
                  width: 100,
                  filterable: true
                },
                {
                  id: 'colDriver',
                  Header: 'Motorista',
                  accessor: row => {
                    if (row.driver_id) {
                      return row.driver.name;
                    }
                    return '~';
                  },
                  filterable: true
                },
                {
                  id: 'colCreatedAt',
                  Header: '',
                  accessor: row =>
                    row.lastStatus
                      ? `${formatTimeDate(row.lastStatus.created_at)[0]} - ${
                          formatTimeDate(row.lastStatus.created_at)[1]
                        }`
                      : '~'
                },
                {
                  id: 'colBtn',
                  Header: '',
                  accessor: row => row.btn,
                  sortable: false
                }
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default RideHistory;
