import axios from 'axios';
import url from './url';

const baseUrl = url.url;
const paggToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA5LzA5L2lkZW50aXR5L2NsYWltcy9hY3RvciI6IlVzZXIiLCJBcHBsaWNhdGlvbklkIjoiMTQiLCJIb2xkZXJJZCI6IjUwIiwiVXNlcklkIjoiNTAiLCJMaW5rQ29kZSI6Ijc3NTY0NjkwIiwiU2FsdCI6IjA3MDQ5ZDY4ZTc1YjRkMTFhZTczYzM1OTkxN2E4MmM3IiwiaXNzIjoiYTA3MzhjZGQiLCJhdWQiOiJlYTJiNDdlYWJjMDQzIn0.jTbwrymYaJYzst_f1wqtvUiB3YNryaY1VRsnUbNvSQw';

const token = () => {
  return localStorage.getItem('token');
};

function identify() {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/identify`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function updatePassword(password, newPassword) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/update-password`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { password, newPassword }
  });
}

function bankAccount(
  holderName,
  taxDocument,
  bankNumber,
  accountNumber,
  bankBranchNumber,
  variation,
  type
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/bank-account`,
    data: { holderName, taxDocument, bankNumber, accountNumber, bankBranchNumber, variation, type }
  });
}

function driverHistory(index = 0, length = 30) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/driver-history`,
    headers: { Authorization: `Bearer ${token()}` },
    params: { index, length }
  });
}

function signIn(email, password) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signin`,
    data: { email, password }
  });
}

function getDrivers(index = 0, length = 30, list = 'unstated', name = null) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/drivers`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      name,
      ...(Boolean(list) && { list })
    }
  });
}

function getDriversLocations() {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/available-drivers`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getSplitters(index = 0, length = 30, name = null) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/splitters`,
    headers: { Authorization: `Bearer ${token()}` },
    params: {
      index,
      length,
      ...(!!name && { name })
    }
  });
}

function updateSplitter(id, splitter) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...splitter
    }
  });
}

function searchSplitter(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getRides(index = 0, length = 30, driver_name = '', destination = '', status = '') {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/rides`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      destination,
      status,
      driver_name
    }
  });
}

function updateDriver(id, status) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-driver`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      driver_id: id,
      approved: status
    }
  });
}

async function updateDriverDocument(id, document, status) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-driver-document`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      driver_id: id,
      approved: status,
      document
    }
  });
}

function userSignUp(name, email, password, phone, cpf) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signup`,
    data: {
      name,
      email,
      password,
      phone,
      cpf
    }
  });
}


//express riomar 

function createSplitter(id, splitter) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/create-splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...splitter
    }
  });
}

function driverSignup(
  name,
  email,
  password,
  phone,
  cpf,
  type,
  description,
  plate,
  cityId,
  zipcode,
  district,
  number,
  line1,
  line2,
  holderName,
  taxDocument,
  bankNumber,
  accountNumber,
  bankBranchNumber,
  variation,
  accountType
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/driver-signup`,
    data: {
      name,
      email,
      password,
      phone,
      cpf,
      vehicle: {
        plate,
        type,
        description
      },
      address: {
        city_id: cityId,
        zipcode,
        district,
        number,
        line1,
        line2
      },
      bankAccount: {
        holderName,
        taxDocument,
        bankNumber,
        accountNumber,
        bankBranchNumber,
        variation,
        type: accountType
      }
    }
  });
}

function getAddress(zipcode) {
  return axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${zipcode}/json/`
  });
}

function getCities(stateId) {
  return axios({
    method: 'get',
    params: { state_id: stateId },
    url: `${baseUrl}/account/cities`
  });
}

function getStates() {
  return axios({
    method: 'get',
    baseURL: `${baseUrl}/account/states`
  });
}

function sendImage(request, file) {
  const formData = new FormData();
  formData.set('image', file);
  let requisition;

  switch (request) {
    case 'profile':
      requisition = 'user-image';
      break;
    case 'crlv':
      requisition = 'driver-vehicle-document';
      break;
    case 'records':
      requisition = 'driver-criminal-records';
      break;
    default:
      requisition = `driver-${request}`;
      break;
  }

  const Url = `${baseUrl}/account/${requisition}`;
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token()}`
    },
    data: formData,
    baseURL: Url
  });
}


/**
 *
 *
 * @param {String} rideId the ride id
 * @returns url to image
 */
function getRideMap(rideId) {
  return `https://s3.us-east-2.amazonaws.com/beelog/dev/ride-images/${rideId}.png`;
}

export {
  identify,
  signIn,
  getDrivers,
  getDriversLocations,
  getSplitters,
  updateSplitter,
  searchSplitter,
  getRides,
  updateDriver,
  updateDriverDocument,
  updatePassword,
  bankAccount,
  driverHistory,
  driverSignup,
  userSignUp,
  getAddress,
  getStates,
  getCities,
  sendImage,
  getRideMap,
  createSplitter
};
