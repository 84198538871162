// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Streetview from '@material-ui/icons/Streetview';
import Person from '@material-ui/icons/Person';
import History from '@material-ui/icons/History';
import ExitToApp from '@material-ui/icons/ExitToAppOutlined';

import UserProfile from '../views/Pages/UserProfile';
import TimelinePage from '../views/Pages/Timeline';
import RTLSupport from '../views/Pages/RTLSupport';
import DriverHistory from '../views/Tables/DriverHistory';
import Ride from '../views/Pages/Ride';

import pagesRoutes from './pages';
import UpdatePassword from '../views/Pages/UpdatePassword';
import LoginPage from '../views/Pages/LoginPage';
import RideHistory from '../views/Tables/RideHistory';
import Splitters from '../views/Tables/Splitters';
import DriversMap from '../views/Maps/DriversMap';
import Driver from '../views/Pages/Driver';
import { driverHistory } from '../requests/requests';
import CreateSplitter from '../views/Pages/CreateSplitter';

const pages = [
  {
    path: '/timeline-page',
    name: 'Timeline Page',
    mini: 'TP',
    component: TimelinePage
  },
  {
    path: '/rtl/rtl-support-page',
    name: 'RTL Support',
    mini: 'RS',
    component: RTLSupport
  }
].concat(pagesRoutes);

const dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: DashboardIcon,
  //   component: Dashboard
  // },
  {
    path: '/user-page',
    name: 'Perfil de Usuario',
    icon: Person,
    component: UserProfile,
    hidden: true
  },
  {
    path: '/driver-history',
    name: 'Motoristas',
    icon: History,
    component: DriverHistory
  },
  {
    path: '/ride-history',
    name: 'Corridas',
    icon: History,
    component: RideHistory
  },
  {
    path: '/splitters',
    name: 'Recebedores',
    icon: History,
    component: Splitters
  },
  {
    path: '/map',
    name: 'Mapa',
    icon: Streetview,
    component: DriversMap
  },
  {
    path: '/ride',
    name: 'Corrida',
    icon: DashboardIcon,
    component: Ride,
    hidden: true
  },
  {
    path: '/update-password',
    name: 'Atualizar Senha',
    icon: DashboardIcon,
    component: UpdatePassword,
    hidden: true
  },
  {
    path: '/driver-detail',
    name: 'Motorista',
    icon: DashboardIcon,
    component: Driver,
    hidden: true
  },
  {
    path: '/csplitter',
    name: 'Completar Cadastro',
    icon: DashboardIcon,
    component: CreateSplitter,
    hidden: true
  },
  // {
  //   collapse: true,
  //   path: '-page',
  //   name: 'Pages',
  //   state: 'openPages',
  //   icon: Image,
  //   views: pages
  // },
  // {
  //   collapse: true,
  //   path: '/components',
  //   name: 'Components',
  //   state: 'openComponents',
  //   icon: Apps,
  //   views: [
  //     {
  //       path: '/components/buttons',
  //       name: 'Buttons',
  //       mini: 'B',
  //       component: Buttons
  //     },
  //     {
  //       path: '/components/grid-system',
  //       name: 'Grid System',
  //       mini: 'GS',
  //       component: GridSystem
  //     },
  //     {
  //       path: '/components/panels',
  //       name: 'Panels',
  //       mini: 'P',
  //       component: Panels
  //     },
  //     {
  //       path: '/components/sweet-alert',
  //       name: 'Sweet Alert',
  //       mini: 'SA',
  //       component: SweetAlert
  //     },
  //     {
  //       path: '/components/notifications',
  //       name: 'Notifications',
  //       mini: 'N',
  //       component: Notifications
  //     },
  //     { path: '/components/icons', name: 'Icons', mini: 'I', component: Icons },
  //     {
  //       path: '/components/typography',
  //       name: 'Typography',
  //       mini: 'T',
  //       component: Typography
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: '/forms',
  //   name: 'Forms',
  //   state: 'openForms',
  //   icon: 'content_paste',
  //   views: [
  //     {
  //       path: '/forms/regular-forms',
  //       name: 'Regular Forms',
  //       mini: 'RF',
  //       component: RegularForms
  //     },
  //     {
  //       path: '/forms/extended-forms',
  //       name: 'Extended Forms',
  //       mini: 'EF',
  //       component: ExtendedForms
  //     },
  //     {
  //       path: '/forms/validation-forms',
  //       name: 'Validation Forms',
  //       mini: 'VF',
  //       component: ValidationForms
  //     },
  //     { path: '/forms/wizard', name: 'Wizard', mini: 'W', component: Wizard }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: '/tables',
  //   name: 'Tables',
  //   state: 'openTables',
  //   icon: GridOn,
  //   views: [
  //     {
  //       path: '/tables/regular-tables',
  //       name: 'Regular Tables',
  //       mini: 'RT',
  //       component: RegularTables
  //     },
  //     {
  //       path: '/tables/extended-tables',
  //       name: 'Extended Tables',
  //       mini: 'ET',
  //       component: ExtendedTables
  //     },
  //     {
  //       path: '/tables/react-tables',
  //       name: 'React Tables',
  //       mini: 'RT',
  //       component: ReactTables
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: '/maps',
  //   name: 'Maps',
  //   state: 'openMaps',
  //   icon: Place,
  //   views: [
  //     {
  //       path: '/maps/google-maps',
  //       name: 'Google Maps',
  //       mini: 'GM',
  //       component: GoogleMaps
  //     },
  //     {
  //       path: '/maps/full-screen-maps',
  //       name: 'Full Screen Map',
  //       mini: 'FSM',
  //       component: FullScreenMap
  //     },
  //     {
  //       path: '/maps/vector-maps',
  //       name: 'Vector Map',
  //       mini: 'VM',
  //       component: VectorMap
  //     }
  //   ]
  // },
  // { path: '/widgets', name: 'Widgets', icon: WidgetsIcon, component: Widgets },
  // { path: '/charts', name: 'Charts', icon: Timeline, component: Charts },
  // { path: '/calendar', name: 'Calendar', icon: DateRange, component: Calendar },
  { path: '/driver/login', name: 'Sair', icon: ExitToApp, component: LoginPage },
  { redirect: true, path: '/', pathTo: '/driver-history', name: 'Historico' }
];
export default dashRoutes;
