import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { withStyles, Select, InputLabel, MenuItem } from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardIcon from '../../components/Card/CardIcon';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import { debounce } from "lodash";

import { getDrivers } from '../../requests/requests';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react';
// import { formatTimeDate } from '../../helpers/functions';
import Driver from '../Pages/Driver';
// express riomar
import Edit from '@material-ui/icons/Edit';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class DriverHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: 'unstated',
      table: {
        base: [],
        pages: '',
        loading: false
      },
      name: "",
      goToDriver: false,
      driver: {},
      goToSplitter: false, 
      driverId: null
    };

    this.fetchDataWithDebounce = debounce(this.fetchData, 500);
  }

  shouldComponentUpdate(nextState) {
    const { list } = this.state;
    if (nextState.list !== list) {
      return true;
    }
    return false;
  }

  getDocumentSituation = driver => {
    if (
      !driver.approved_at &&
      (driver.documents == null ||
        driver.documents.criminal_records == null ||
        driver.documents.cnh == null ||
        driver.documents.vehicle_document == null ||
        driver.documents.selfie == null)
    ) {
      return true;
    }
    return false;
  };

  goToDriver = (evt, driver) => {
    evt.preventDefault();
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/driver-detail';
  };

  goToCreateSplitter = (evt, driver) => {
    evt.preventDefault();
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/csplitter';
  };

  handleChange = async evt => {
    console.log(evt.target.name);
    await this.setState({ [evt.target.name]: evt.target.value });
    this.refReactTable.fireFetchData();
  };

  onFilteredChange = async (column, value) =>{

    if(column && column.length > 0){
      this.setState(prevState => ({name: column[0].value})) 
    }else{
      this.setState(prevState => ({name: ""})) 
    }
  }

  fetchData = async state => {
    const { list } = this.state;
    this.setState(prevState => ({ table: { ...prevState.table, loading: true } }));

    try {
      const res = await getDrivers(state.page * state.pageSize, state.pageSize, list, this.state.name);

      res.data.drivers.forEach(driver => {
        driver.btn = (
          <div className="actions-right" style={{ float: 'right' }}>
            <Button
            justIcon
            round
            simple
            color="warning"
            onClick={evt => {
              this.goToCreateSplitter(evt, driver);
            }}>
            <Edit />
            </Button>
            <Button
              justIcon
              round
              simple
              color="info"
              onClick={evt => {
                this.goToDriver(evt, driver);
              }}
              //disabled={this.getDocumentSituation(driver)}
            >
              
              <Search />
            </Button>
          </div>
        );
      });
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          base: res.data.drivers,
          loading: false,
          pages: parseInt(res.data.count / state.pageSize, 10)
        }
      }));
    } catch (err) {
      console.log(err.response);
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          loading: false
        }
      }));
    }
  };

  render() {
    const { classes } = this.props;
    const { table, goToDriver, driver, list } = this.state;

    if (goToDriver) return <Driver driver={driver} />;
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Motoristas</h4>
            </CardHeader>
            <CardBody>
              <InputLabel htmlFor="list">Listar: </InputLabel>
              <Select
                disabled = {this.state.name != null && this.state.name.length > 0}
                value={list}
                onChange={this.handleChange}
                inputProps={{
                  name: 'list',
                  id: 'list'
                }}
              >
                <MenuItem value="unstated">Em espera</MenuItem>
                <MenuItem value="approved">Aprovados</MenuItem>
                <MenuItem value="reproved">Reprovados</MenuItem>
                <MenuItem value="pending">Pendentes</MenuItem>
              </Select>
              <ReactTable
                ref={refReactTable => {
                  this.refReactTable = refReactTable;
                }}
                className="-striped -highlight"
                data={table.base}
                sortable={false}
                defaultPageSize={10}
                pages={table.pages}
                loading={table.loading}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                manual
                onFetchData={this.fetchDataWithDebounce}
                onFilteredChange={this.onFilteredChange}
                columns={[
                  {
                    id: 'colName',
                    Header: 'Nome',
                    accessor: row => row.user.name,
                    maxWidth: 200,
                    filterable: true
                  },
                  {
                    id: 'colContact',
                    Header: 'Contato',
                    accessor: row => row.user.phone,
                    width: 150
                  },
                  {
                    id: 'colSignup',
                    Header: 'Criado em',
                    accessor: row => row.user.signup_date,
                    width: 100
                  },
                  {
                    id: 'colCity',
                    Header: 'Cidade',
                    accessor: row => row.user.address != null ? row.user.address.city.nome : ""
                  },
                  {
                    id: 'colState',
                    Header: 'Estado',
                    accessor: row => row.user.address != null ? row.user.address.city.state.nome : ""
                  },
                  {
                    id: 'colBtn',
                    Header: '',
                    accessor: row => row.btn,
                    sortable: false,
                    filterable: false
                  }
                ]}
                showPageJump={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DriverHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DriverHistory);
