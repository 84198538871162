import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
// import { fitBounds } from 'google-map-react/utils';

import { getDriversLocations } from '../../requests/requests';
import Marker from '../../components/Marker/Marker';
import { K_SIZE } from '../../components/Marker/styles';

export default function DriversMap({ history }) {
  const [drivers, setDrivers] = useState([]);
  const [first, setFirst] = useState(true);
  const [error, setError] = useState();
  // const [center, setCenter] = useState();
  // const [zoom, setZoom] = useState(16);

  async function fetchData() {
    try {
      const res = await getDriversLocations();
      setDrivers(res.data.drivers);
      // console.log(res.data);
      if (first) setFirst(false);
    } catch (err) {
      if (first) {
        history.push('/dashboard');
        setFirst(false);
      }
      setError(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!error) {
      let timeOutFunction = setTimeout(() => fetchData(), 10000);
      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [drivers]);

  if (error) {
    return (
      <div
        style={{ height: '100vh', width: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        Rastreio indisponível.
      </div>
    );
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBTFggiDJGjhbwlGmB86InAKTFBBN-8vno' }}
        defaultZoom={16}
        center={{
          lat: drivers.length > 0 ? drivers[0].current_latitude : -10.9498019,
          lng: drivers.length > 0 ? drivers[0].current_longitude : -37.0843285
        }}
        layerTypes={['TrafficLayer', 'TransitLayer']}
        hoverDistance={K_SIZE / 2}
        // onChange={({ bounds, size }) => {
        //   const { cnt, zm } = fitBounds(bounds, size);
        //   console.log(bounds);
        //   setCenter(cnt);
        //   setZoom(zm);
        // }}
      >
        {drivers.map(driver => {
          let available = 'unavailable';
          if (!driver.in_ride && driver.online) {
            available = 'available';
          } else if (driver.in_ride) {
            available = 'inRide';
          } else {
            available = 'unavailable';
          }
          return (
            <Marker
              key={driver.user_id}
              lat={driver.current_latitude}
              lng={driver.current_longitude}
              text={`${driver.user_id}`}
              tooltip={`${driver.vehicle.plate}\n${driver.vehicle.type.name}`}
              available={available}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}
