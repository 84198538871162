import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Button from '../../components/CustomButtons/Button';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import LoginPage from './LoginPage';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goToLogin: false
    };
  }

  goToLogin = evt => {
    evt.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    this.setState({ goToLogin: true });
  };

  render() {
    const { classes, txt, location } = this.props;
    const { goToLogin } = this.state;

    if (goToLogin) return <LoginPage />;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{txt || location.state.txt}</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10} align="justify">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit voluptas
                      quisquam, aliquid sequi sapiente eaque blanditiis dolor! Accusantium,
                      necessitatibus, molestiae facere eveniet id, sequi at ducimus sed eligendi
                      exercitationem odio?
                    </p>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <div className={classes.center}>
                <Button round color="primary" type="submit" onClick={this.goToLogin}>
                  Voltar ao Login
                </Button>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
  txt: PropTypes.string.isRequired,
  location: PropTypes.object
};

export default withStyles(registerPageStyle)(Landing);
