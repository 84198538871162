import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Car from '@material-ui/icons/CallMergeTwoTone';

import { Button } from '@material-ui/core';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Timeline from '../../components/Timeline/Timeline';

import { formatTimeDate, formatMoney } from '../../helpers/functions';
import { getRideMap } from '../../requests/requests';

import cardImagesStyles from '../../assets/jss/material-dashboard-pro-react/cardImagesStyles';

const bdgColorHelper = statusId => {
  switch (statusId) {
    case 1:
      return 'info';
    case 2:
      return 'info';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'danger';
    case 6:
      return 'success';
    case 7:
      return 'warning';
    case 8:
      return 'danger';
    case 9:
      return 'danger';
    default:
      return 'danger';
  }
};

class Ride extends Component {
  constructor(props) {
    super(props);

    const ride = JSON.parse(sessionStorage.getItem('ride'));
    console.log(ride);
    this.state = {
      ride,
      status: ride.status.map(item => ({
        inverted: item.status_id % 2 === 0,
        badgeColor: bdgColorHelper(item.status_id),
        badgeIcon: Car,
        title: item.name,
        titleColor: bdgColorHelper(item.status_id),
        body: (
          <div>
            <b>Data: </b>
            {`${formatTimeDate(item.created_at)[0]}`}
            <br />
            <b>Hora: </b>
            {`${formatTimeDate(item.created_at)[1]}`}
            <br />
          </div>
        )
      }))
    };
    sessionStorage.removeItem('ride');
  }

  // TODO: Fazer request de cancelar corrida na API
  cancelRide = () => {};

  // TODO: Verificar quais status podem cancelar a corrida
  canCancel = rideStatus => {
    return false;
  };

  render() {
    const { classes } = this.props;
    const { ride, status } = this.state;
    console.log('status :', status);
    console.log('ride :', ride);

    return (
      <GridContainer justify="center">
        {this.canCancel(ride.lastStatus.status_id) ? (
          <GridItem container justify="flex-end" xs={12} sm={12} md={12}>
            <Button
              variant="raised"
              color="default"
              onClick={() => {
                this.cancelRide();
              }}
              style={{ backgroundColor: 'red', color: 'white' }}
            >
              Cancelar
            </Button>
          </GridItem>
        ) : (
          ''
        )}
        <GridItem xs={12} sm={8} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <img
                  className={classes.cardImgTop}
                  data-src="holder.js/100px180/"
                  alt="Mapa"
                  style={{ height: '100%', width: '100%', display: 'block' }}
                  src={ride.map}
                  data-holder-rendered="true"
                />
                <CardBody>
                  <h3>Informações adicionais</h3>
                  <p>{ride.type.name}</p>
                  <hr />
                  <p>
                    {`${ride.driver.name}`}
                    <br />
                    {`${ride.distance / 1000} quilômetros.`.replace('.', ',')}
                    <br />
                    {`Partindo de ${ride.originAddress}`}
                    <br />
                    {`Destino ${ride.destinationAddress}`}
                    <br />
                    {ride.fare.name}
                  </p>
                  <hr />
                  {ride.measures !== null ? (
                    <p>
                      {`Pacote ${ride.measures.name}`}
                      <br />
                      {ride.measures.note}
                    </p>
                  ) : (
                    ''
                  )}
                  <hr />
                  <h6>Preços</h6>
                  <p>
                    {`Distancia: R$ ${formatMoney(ride.price.distance_price)}`}
                    <br />
                    {`Volume: R$ ${formatMoney(ride.price.volume_price)}`}
                    <br />
                    {`Seguro: R$ ${formatMoney(ride.price.insurance_price)}`}
                    <br />
                    {`Desconto: R$ ${formatMoney(ride.price.discount_price)}`}
                    <br />
                    <strong>{`Total: R$ ${formatMoney(ride.price.total_price)}`}</strong>
                  </p>
                  <hr />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={8} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card plain>
                <CardBody plain>
                  <Timeline stories={status} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(cardImagesStyles)(Ride);
